// Images, videos, etc

.figure {
   @include media-breakpoint-up(md) {
      position: relative;
      &.left, &.right {
         width: 45%;
         top: 8px;

         img, iframe {
            margin-bottom: 0;
         }

         // if image is floated left or right to let things wrap around it, take the top padding off the following element
         & + * {
            padding-top: 0;
         }
      }
      &.left {
         float: left;
         margin-right: 20px;
      }
      &.right {
         float: right;
         margin-left: 20px;
      }
   }
}

.image-grid {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;

   .cell {
      width: 100%;
      padding-bottom: 15px;
   }

   @include media-breakpoint-up(md) {
      &.col2 {
         .cell {
            width: 48%;
         }
      }
      &.col3 {
         .cell {
            width: 31%;
         }
      }
   }
   img {
      border-radius: 3px;
   }
   a img:hover {
      opacity: 0.8;
   }
}