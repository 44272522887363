// Big wide background sections

.jumbotron {
   &.dark, .bg-dark {
      color: $gray-300;
      @for $i from 1 through 6 {
         h#{$i}, .h#{$i} {
            color: $gray-200;
         }
      }
   }
}