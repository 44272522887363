// About page stuff

$organizerGutter: 10px;
.organizers {
   display: flex;
   flex-wrap: wrap;
   margin-left: -#{$organizerGutter};
   margin-right: -#{$organizerGutter};
}

.organizer {
   margin-bottom: map-get($spacers, 8);
   padding-left: $organizerGutter;
   padding-right: $organizerGutter;
   font-size: .9rem;
   line-height: 1.4;
   .name {
      margin: 10px 0 25px;
   }
   .position {
      position: relative;
      bottom: 18px;
      font-style: italic;
      font-size: .8rem;
      color: $gray-500;
      font-family: $font-family-serif;
   }

   .phone, .email, .website {
      position: relative;
      padding-left: 24px;
      margin-bottom: 5px;
      &:before {
         font-family: 'Fontello';
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
      }
   }
   .phone:before {
      content: '\e818';
   }
   .email:before {
      content: '\e800';
   }
   .website:before {
      content: '\e82a';
      left: 4px;
      font-size: 1rem;
   }

   @include media-breakpoint-up(sm) {
      width: 50%;
   }
   @include media-breakpoint-up(lg) {
      width: 33.3333%;
   }
}