// breadcrumbs

.breadcrumb {
   font-family: $font-family-sans-serif;
}

.breadcrumb-item {
   display: inline;
   font-size: 0.8rem;
   line-height: 1.3;
   word-break: break-word;
   color: $gray-500;
   a {
      color: inherit;
      &:hover {
         color: $link-hover-color;
      }
   }
   &.active {
      cursor: default;
   }
   &:before {
      font-size: .6rem;
      bottom: 2px;
      position: relative;
   }
   &.home {
      .icon {
         font-size: 1.2rem;
         vertical-align: top;
      }
      span {
         display: none;
      }
   }
}

@include media-breakpoint-up(md) {
   .breadcrumb-item {
      font-size: 1rem;
      line-height: $line-height-base;
   }
}