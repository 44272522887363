// custom print styling

body#print {
   padding-bottom: 70px;
}

body#print,
body#print .table {
   background-color: #fff;
   color: #000;
   font-size: 16px;
   strong, b {
      color: #000;
   }
   * {
      font-family: $font-family-sans-serif;
   }
}

body#print {
   .table {
      border-color: $gray-400;
      thead th {
         border-color: $gray-400;
      }
      td {
         border-color: $gray-500;
      }
   }

   h1 {
      font-size: 36px;
   }
   h2 {
      font-size: 28px;
   }
   h3 {
      font-size: 22px;
   }
   h4 {
      font-size: 18px;
   }
   h5 {
      font-size: 16px;
   }
   h6 {
      font-size: 16px;
   }
}

.print-heading {
   display: flex;
   align-items: center;
   padding: 2rem 0 1rem;
   img {
      margin-right: 14px;
   }
   h1 {
      margin: 0 0 0 20px;
      font-size: 2.3rem;
   }
   .fairDate {
      margin: 0 0 0 20px;
      font-family: $font-family-sans-serif;
      font-weight: 700;
      font-size: 20px;
   }
}

.print-instructions {
   display: flex;
   align-items: center;
   font-size: 1rem;
   padding: 0px 10px 0px 5px;
   .icon {
      font-size: 1.6rem;
   }
   span {
      line-height: 1.1rem;
   }
}

body#print .accountDetails {
   tr {
      td {
         background-color: transparent;
         font-size: 0.9rem;
         &:first-child {
            width: 25%;
            font-weight: bold;
         }
         ul, ol {
            margin: 0;
            padding: 0 0 0 20px;
            font-family: inherit;
         }
      }
   }
}

// Invoices
body#print {
   table.invoice {
      tr.total td {
         font-size: 18px;
      }
   }
   .invoiceHead, .invoiceFoot {
      display: flex;
      > div {
         width: 50%;
      }
   }
   .invoiceFoot {
      font-size: 14px;
   }
}

@media print {
   body#print {
      padding-bottom: 0;
   }
   .print-instructions {
      display: none;
   }
}