// loading spinner

#loading {
   &.modal.show {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
   }
   .modal-content {
      background-color: transparent;
      border: none;
   }
   .icon {
      color: $white;
      font-size: 1.5rem;
   }
}