// entry lists

.entries.list {
   .listItem {
      padding-bottom: map-get($spacers, 7);
      margin-bottom: map-get($spacers, 7);
      &:last-child {
         margin-bottom: 0;
      }
   }
   .title {
      $side-padding: 16px;
      padding: 20px $side-padding 10px;
      background-color: $gray-200;
      border-left: 5px solid $gray-300;
      border-radius: 3px;
      position: relative;
      left: -#{$side-padding};
      margin-bottom: 20px;

      .header {
         color: $gray-700;
         font-size: 1.3rem;
         line-height: 1;
         margin-bottom: 15px;
      }
      a {
         color: inherit;
         &:hover {
            color: $link-hover-color;
         }
      }
   }
   .date {
      font-style: italic;
      position: relative;
      bottom: 5px;
      font-family: $font-family-serif;
      font-size: .9rem;
   }
   .blockquote {
      background-color: transparent;
      padding: 0 0 10px;
      margin: 0;
      border: none;
      p {
         margin: 0;
         font-family: $font-family-serif;
         font-size: 1rem;
         color: $gray-600;
         font-weight: normal;
         line-height: $line-height-base;
         &:before {
            left: -22px;
            top: -15px;
         }
      }
   }
}

.profile.list {
   @include media-breakpoint-up(md) {
      .listItem {
         display: flex;
      }
   }

   .listItem {
      padding-top: map-get($spacers, 6);
      margin-top: map-get($spacers, 6);
      border-top: 1px solid $border-color;
      &:first-child {
         padding: 0;
         margin: 0;
         border: none;
      }
   }

   .title {
      font-size: $h4-font-size;
      line-height: 1;
      a {
         color: inherit;
         &:hover {
            color: $link-hover-color;
         }
      }
   }

   .picContainer {
      margin-right: 30px;
      margin-bottom: 20px;
   }

   .pic {
      display: block;
      background-color: $white;
      width: 130px;
      position: relative;
   }

   .default {
      padding-bottom: 98%;
      height: 0;
      border: $thumbnail-padding solid $gray-300;
      &:before {
         content: '\e814';
         font-family: 'Fontello';
         font-size: 80px;
         position: absolute;
         left: 50%;
         top: 50%;
         z-index: 1;
         color: $gray-500;
         line-height: normal;
         margin: -40px 0 0 -27px;
      }
   }
}
