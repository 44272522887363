// Forms

.form {
   background-color: $gray-200;
   position: relative;
   padding: 30px;
   width: 100%;
   border-radius: $border-radius;
   border-bottom: $border-edge;
   &.noFormBg {
      background-color: transparent;
      padding: 0;
      border: none;
   }
}

form {
   p {
      color: lighten($body-color, 20%);
   }
   hr {
      margin: map-get($spacers, 7) 0;
      border-color: $gray-400;
   }
}

label {
   @extend h5;
}

.req {
   color: $danger;
}

.form-control {
   font-size: $font-size-sm;
   font-family: $font-family-sans-serif;

   &:focus {
      box-shadow: none;
   }

   // placeholder values taken from mixins/vendor-prefixes.scss
   &::-moz-placeholder {
      opacity: 1;
      font-family: $font-family-serif;
      font-style: italic;
      color: $input-placeholder-color;
   }
   &:-ms-input-placeholder {
      font-family: $font-family-serif;
      font-style: italic;
      color: $input-placeholder-color;
   }
   &::-webkit-input-placeholder {
      font-family: $font-family-serif;
      font-style: italic;
      color: $input-placeholder-color;
   }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

textarea.form-control {
   min-height: 120px;
   line-height: 1.5;
}

.custom-checkbox, .custom-radio {
   font-size: $font-size-base;
   font-weight: 400;
   line-height: 1.5;
   .custom-control-label {
      font-weight: normal;
      font-size: $font-size-base;
      color: $body-color;
      &:before, &:after {
         top: .25rem;
      }
   }
}

select.form-control:not([size]):not([multiple]) {
   height: calc(3.1rem);
}

.btn-submit {
   width: auto;
}

// honey pot field
input[name="website"] {
   display: none;
}

form {
   .note {
      font-size: .9rem;
      color: lighten($gray-600, 5%);
      margin-bottom: 5px;
      position: relative;
      bottom: 10px;
      line-height: 1.6;
      font-family: $font-family-sans-serif;
   }
   .iconNote {
      position: relative;
      margin-bottom: 40px;
      padding-left: 50px;
      p {
         margin: 0;
      }
      .icon {
         font-size: 40px;
         position: absolute;
         left: -8px;
         top: -10px;
         z-index: 1;
         opacity: 0.6;
      }
   }
}

// form tags
.bootstrap-tagsinput {
   $tagHeight: 30px;
   $inputYpadding: 0.7rem;

   @extend .form-control;
   padding: 0 $inputYpadding;

   .badge, input {
      margin-bottom: $inputYpadding;
   }

   input {
      width: 120px;
      height: 100%;
      border: none;
      outline: none;
      color: $input-color !important;
      font-size: $font-size-sm;
      &::-webkit-input-placeholder {
         font-family: $font-family-serif;
         font-style: italic;
         color: $input-placeholder-color;
      }
      &:focus {
         outline: none;
         border-color: none;;
      }
   }

   .badge {
      margin-right: 5px;
   }

   // remove button
   span[data-role='remove'] {
      cursor: pointer;
      display: inline-block;
      height: 100%;
      width: 20px;
      text-align: center;
      &:after {
         content: "×";
         color: inherit;
      }
      &:hover:after {
         color: $link-hover-color;
      }
   }
}

// Validation styling
$error-text-color: #d25e5b;
$error-border-color: #e49896;
.has-error {
   *, *:focus {
      box-shadow: none !important;
   }
   label, .control-label {
      color: #333333; // keep it dark
   }
   .form-control {
      border-color: $input-border-color;
      color: $input-color;
   }

   // error styling
   .form-control:required,
   .help-block {
      position: relative;
      border-color: $error-border-color;
      color: $error-text-color;
      &:focus {
         border-color: $error-border-color;
         color: $error-text-color;
      }
   }
   .list .help-block {
      left: 20px;
   }
   select.form-control:required {
      color: $input-color;
   }

   .help-block {
      display: block;
      padding-top: 7px;
      font-size: 0.9rem;
      line-height: 1.4;
      &:before {
         content: '\e825';
         font-family: 'Fontello';
         margin-right: 5px;
      }
   }
}

.radiocheckStackError {
   .help-block {
      bottom: 5px;
      //		left: 26px;
   }
}

.radiocheckInlineError {
   .help-block {
      bottom: 14px;
   }
}

.backend-error {
   color: $error-text-color;
   font-size: 14px;
   position: relative;
   bottom: 28px;
   line-height: 18px;
   &.list {
      left: 20px;
   }
}

#response {
   ul.errors {
      margin-bottom: 25px;
   }
   .btn {
      i {
         font-size: 12px;
      }
   }
}

// add / delete rows of fields
.lister {
   width: 100%;
   tr {
      td {
         padding-bottom: 10px;
         &:not(:last-child) {
            padding-right: 20px;
         }
      }
   }
}





