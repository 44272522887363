// icons

.breadcrumb-item,
.sidenav a {
   &:before {
      font-family: 'Fontello';
   }
}

.icon-list {
   list-style: none;
   margin: 0;
   padding: 0;
   display: flex;
   flex-wrap: wrap;
   li {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: (100% / 2);
      border: 1px solid $border-color;
      padding: 20px 0;
      text-align: center;
      font-family: $font-family-sans-serif;
      &:hover {
         background-color: $gray-200;
      }
   }
   div {
      width: 100%;
   }
   .icon-example {
      font-size: 1.5rem;
      margin-bottom: 10px;
      color: $gray-900;
   }
   .icon-text {
      font-size: .8rem;
   }
   .icon-class {
      font-weight: 700;
      display: inline-block;
      margin-bottom: 5px;
      color: $gray-700;
   }

   @include media-breakpoint-up(sm) {
      li {
         width: (100% / 3);
      }
   }
   @include media-breakpoint-up(md) {
      li {
         width: (100% / 4);
      }
   }
   @include media-breakpoint-up(lg) {
      li {
         width: (100% / 6);
      }
   }
}