@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?58503235');
  src: url('../fonts/fontello.eot?58503235#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff2?58503235') format('woff2'),
       url('../fonts/fontello.woff?58503235') format('woff'),
       url('../fonts/fontello.ttf?58503235') format('truetype'),
       url('../fonts/fontello.svg?58503235#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello.svg?58503235#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-mail:before { content: '\e800'; } /* '' */
.icon-search:before { content: '\e801'; } /* '' */
.icon-right-open:before { content: '\e802'; } /* '' */
.icon-left-open:before { content: '\e803'; } /* '' */
.icon-left-big:before { content: '\e804'; } /* '' */
.icon-right-big:before { content: '\e805'; } /* '' */
.icon-location:before { content: '\e806'; } /* '' */
.icon-up-open:before { content: '\e807'; } /* '' */
.icon-down-open:before { content: '\e808'; } /* '' */
.icon-cancel:before { content: '\e809'; } /* '' */
.icon-calendar:before { content: '\e80a'; } /* '' */
.icon-tags:before { content: '\e80b'; } /* '' */
.icon-tag:before { content: '\e80c'; } /* '' */
.icon-user:before { content: '\e80d'; } /* '' */
.icon-quote:before { content: '\e80e'; } /* '' */
.icon-home:before { content: '\e80f'; } /* '' */
.icon-ok:before { content: '\e810'; } /* '' */
.icon-ok-circled:before { content: '\e811'; } /* '' */
.icon-cancel-circled:before { content: '\e812'; } /* '' */
.icon-book:before { content: '\e813'; } /* '' */
.icon-book-1:before { content: '\e814'; } /* '' */
.icon-book-2:before { content: '\e815'; } /* '' */
.icon-bookmark:before { content: '\e816'; } /* '' */
.icon-bookmark-empty:before { content: '\e817'; } /* '' */
.icon-phone:before { content: '\e818'; } /* '' */
.icon-mouse:before { content: '\e819'; } /* '' */
.icon-download:before { content: '\e81a'; } /* '' */
.icon-plus:before { content: '\e81b'; } /* '' */
.icon-minus:before { content: '\e81c'; } /* '' */
.icon-clock:before { content: '\e81d'; } /* '' */
.icon-info-circled:before { content: '\e81e'; } /* '' */
.icon-info:before { content: '\e81f'; } /* '' */
.icon-cog:before { content: '\e820'; } /* '' */
.icon-cog-alt:before { content: '\e821'; } /* '' */
.icon-edit:before { content: '\e822'; } /* '' */
.icon-clipboard:before { content: '\e823'; } /* '' */
.icon-book-open:before { content: '\e824'; } /* '' */
.icon-attention-circled:before { content: '\e825'; } /* '' */
.icon-attention:before { content: '\e826'; } /* '' */
.icon-picture:before { content: '\e827'; } /* '' */
.icon-zoom-in:before { content: '\e828'; } /* '' */
.icon-zoom-out:before { content: '\e829'; } /* '' */
.icon-globe:before { content: '\e82a'; } /* '' */
.icon-pencil:before { content: '\e82b'; } /* '' */
.icon-trash-empty:before { content: '\e82c'; } /* '' */
.icon-logout:before { content: '\e82d'; } /* '' */
.icon-login:before { content: '\e82e'; } /* '' */
.icon-cw:before { content: '\e82f'; } /* '' */
.icon-ccw:before { content: '\e830'; } /* '' */
.icon-arrows-cw:before { content: '\e831'; } /* '' */
.icon-print:before { content: '\e832'; } /* '' */
.icon-credit-card:before { content: '\e833'; } /* '' */
.icon-spin4:before { content: '\e834'; } /* '' */
.icon-spin6:before { content: '\e839'; } /* '' */
.icon-th-list:before { content: '\f009'; } /* '' */
.icon-th-thumb:before { content: '\f00a'; } /* '' */
.icon-move:before { content: '\f047'; } /* '' */
.icon-users:before { content: '\f064'; } /* '' */
.icon-link-ext:before { content: '\f08e'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-money:before { content: '\f0d6'; } /* '' */
.icon-mobile:before { content: '\f10b'; } /* '' */
.icon-circle-empty:before { content: '\f10c'; } /* '' */
.icon-attention-alt:before { content: '\f12a'; } /* '' */
.icon-ticket:before { content: '\f145'; } /* '' */
.icon-pound:before { content: '\f154'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-bank:before { content: '\f19c'; } /* '' */
.icon-fax:before { content: '\f1ac'; } /* '' */
.icon-history:before { content: '\f1da'; } /* '' */
.icon-sliders:before { content: '\f1de'; } /* '' */
.icon-newspaper:before { content: '\f1ea'; } /* '' */
.icon-map-o:before { content: '\f278'; } /* '' */
.icon-wpforms:before { content: '\f298'; } /* '' */
.icon-twitter-squared:before { content: '\f304'; } /* '' */
.icon-facebook-squared:before { content: '\f308'; } /* '' */