// footer

.footer-top, .footer-bottom {
   font-size: .9rem;
   * {
      font-family: $font-family-sans-serif;
   }
   ul {
      list-style: none;
      margin: 0;
      padding: 0;
   }
}

.footer-top {
   background-color: $gray-900;
   padding: map-get($spacers, 8) 0 map-get($spacers, 9);
   color: $gray-600;
   h4 {
      color: $gray-100;
      font-size: 1rem;
      text-transform: uppercase;
   }
   li {
      color: $gray-500;
      a {
         color: inherit;
         &:hover {
            color: $gray-200;
         }
      }
   }

   @include media-breakpoint-down(sm) {
      text-align: center;
   }
}

// newsletter form
.signup-form {
   .form-control {
      padding-top: .4rem;
      padding-bottom: .4rem;
      border: none;
   }
   .signup-btn {
      @extend .form-control;
      cursor: pointer;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-left: 8px;
      padding-right: 8px;
      width: auto;
      text-transform: uppercase;
      background-color: $gray-500;
      &:hover {
         background-color: $gray-400;
         color: lighten(theme-color('primary'), 6%);
      }
   }
   .signup-field {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-color: $gray-300;
      &:focus {
         border-color: $input-border-color;
      }
      &::-moz-placeholder {
         color: $gray-500;
      }
      &:-ms-input-placeholder {
         color: $gray-500;
      }
      &::-webkit-input-placeholder {
         color: $gray-500;
      }
      @include media-breakpoint-only(lg) {
         width: 70%;
         padding-left: .7rem;
         padding-right: .7rem;
      }
   }
}

.footer-bottom {
   background: $black;
   color: $gray-600;
   padding-top: map-get($spacers, 3);
   padding-bottom: map-get($spacers, 3);
   text-align: center;
   .footer-menu {
      display: flex;
      flex-wrap: wrap;
   }
   p {
      padding: 0;
      margin: 0;
      width: 100%;
      color: inherit;
   }
   ul {
      width: 100%;
   }
   a {
      color: inherit;
      text-decoration: none;
      font-weight: normal;
      border: none;
      display: inline;
      &:hover {
         color: $gray-200;
      }
   }

   @include media-breakpoint-up(lg) {
      text-align: left;
      .footer-menu {
         flex-wrap: nowrap;
      }
      p {
         width: auto;
         order: 1;
      }
      ul {
         width: auto;
         margin-left: auto;
         order: 2;
      }
      li {
         margin-left: 1rem;
         display: inline-block;
         &.list-inline-item:not(:last-child) {
            margin-right: 5px;
         }
      }
   }
}