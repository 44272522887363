// Carousels

.carousel-inner {
   -webkit-transform-style: preserve-3d;
   -webkit-backface-visibility: hidden;
}

.carousel-item img {
   width: 100%;
}

.carousel-fade {
   .carousel-item {
      transition-duration: 2s;
   }
}

.carousel-caption {
   background-color: rgba(36, 43, 36, 0.5);
   padding: 15px 0 20px;
   bottom: 0;
   * {
      font-family: $font-family-sans-serif;
      color: $white;
   }
   @for $i from 1 through 6 {
      h#{$i}, .h#{$i} {
         line-height: 1.1;
         font-size: $h4-font-size;
         color: $gray-100;
         margin-bottom: 5px;
      }
   }
   p {
      line-height: 1.4;
      text-shadow: 0px 1px 2px rgba(36, 47, 43, 0.8);
      color: $gray-100;
      font-size: .9rem;
   }
}

.carousel-control-next,
.carousel-control-prev {
   background-color: rgba(39, 39, 39, 0.7);
   border-radius: $border-radius;
   width: $carousel-control-width;
   height: $carousel-control-width;
   top: 50%;
   margin-top: -#{$carousel-control-width / 2};
   &:focus {
      color: $carousel-control-color;
      text-decoration: none;
      outline: 0;
      opacity: $carousel-control-opacity;
   }
}

.carousel-control-next {
   right: 20px;
}

.carousel-control-prev {
   left: 20px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
   position: relative;
}

.carousel-control-next-icon {
   left: 6px;
}

.carousel-control-prev-icon {
   left: 2px;
}

.carousel-indicators {
   li {
      border-radius: 50%;
      cursor: pointer;
   }
}
