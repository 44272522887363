// Variables specific to CHELSEA

// custom gray scale
$white:             #ffffff;
$gray-100:          #f1f5f7;
$gray-200:          #e5ebec;
$gray-300:          #dbe2e4;
$gray-400:          #c8cece;
$gray-500:          #a4adaf;
$gray-600:          #777e82;
$gray-700:          #5a5f65;
$gray-800:          #3d4144;
$gray-900:          #212426;
$black:             #191b1d;

// our theme colors
$navy:              #012f47;
$dark-blue:         #063C57;
$blue:              #0D577C;
$light-blue:        #5594B4;
$faded-blue:        #dce6eb;
$yellow:            #E1CB8E;
$faded-yellow:      #dad1b8;
$red:               #dc4845;
$faded-red:         #d25e5b;

// extra colors
$indigo:            #6610f2;
$purple:            #6f42c1;
$pink:              #e83e8c;
$orange:            #fd7e14;
$green:             #2ba57d;
$teal:              #20c997;
$cyan:              #17a2b8;

// social media colors
$facebook-color:    #4668B3;
$twitter-color:     #2FBBED;
$instagram-color:   #E4405F;

// BS's theme
$primary-darker:    $navy;
$primary-dark:      $dark-blue;
$primary:           $blue;
$primary-light:     $light-blue;
$primary-lighter:   $faded-blue;

$secondary:         $gray-400;
$success:           $green;
$info:              $yellow;
$info-light:        $faded-yellow;
$warning:           $yellow;
$warning-light:     $faded-yellow;
$danger:            $red;
$danger-light:      $faded-red;
$light:             $gray-300;
$dark:              $gray-800;