// scaffolding

.wrapper {
   background-color: $wrapper-bg;
}

// for consistent bottom spacing
.spacing {
   margin-bottom: $spacing;
}

.intro {
   position: relative;
   padding-top: $spacing;
   .breadcrumb {
      position: absolute;
      top: 1em;
   }
}

// equal column heights
.row.equal-height {
   display: flex;
   flex-wrap: wrap;
}

.row.equal-height > [class*='col-'] {
   display: flex;
   flex-direction: column;
}

.card {
   flex: 1;
}