// Tables

.abac table {
   @extend .table;
   @extend .table-bordered;
}

.table {
   background-color: transparent;
   color: darken($body-color, 2%);
   th, td {
      padding-top: .6rem;
      padding-bottom: .6rem;
      line-height: 1.3;
      border-width: 1px;
   }

   thead th {
      background-color: $table-head-bg;
      border-width: $table-border-width;
      vertical-align: middle;
      color: $table-head-color;
      font-weight: normal;
      font-style: italic;
      font-family: $font-family-serif;
   }
   td, td ul, td ol {
      font-family: $font-family-sans-serif;
   }

   &.table-bordered {
      background-color: $table-bg;
   }
   &.equal-columns {
      table-layout: fixed;
   }
   tr.divider td {
      background-color: $table-head-bg;
      padding: 0.3rem;
      text-align: center;
      font-size: .8rem;
      font-weight: bold;
      text-transform: uppercase;
   }

   .btn-link {
      font-size: 0.9rem;
   }
}

.form .table {
   background-color: transparent;
   border-color: darken($table-border-color, 3%);
   th, td {
      border-color: darken($table-border-color, 3%);
   }
   thead th {
      background-color: darken($table-head-bg, 3%);
      color: darken($table-head-color, 2%);
   }

   &.table-bordered {
      background-color: darken($table-bg, 2%);
   }
   &.table-striped {
      tbody tr:nth-of-type(odd) {
         background-color: darken($gray-200, 2%);
      }
   }
}