// moxes and bodules

.box, .module, .endcta {
   display: block;
   position: relative;
   width: 100%;
}

.box, .module {
   .inner {
      padding: map-get($spacers, 2);
      @include media-breakpoint-up(sm) {
         padding: map-get($spacers, 5);
      }
   }
}

.box,
.module:not(.module-naked),
.endcta,
.nav-tabs.nav.module {
   background-color: $gray-200;
   border-bottom: $border-edge;
   border-radius: $border-radius;
}

.box {
   &.big {
      .inner {
         padding: map-get($spacers, 8);
      }
   }
}

.module {
   @for $i from 1 through 6 {
      h#{$i}, .h#{$i} {
         font-size: $h3-font-size;
      }
   }
}

.endcta {
   text-align: center;
   .inner {
      padding: map-get($spacers, 7);
   }
   .header {
      font-size: $h3-font-size;
      position: relative;
      margin-bottom: 1.2rem;
      padding-bottom: 1.2rem;
      &:after {
         $linewidth: 160px;
         content: '';
         width: $linewidth;
         height: 1px;
         background-color: $gray-400;
         position: absolute;
         bottom: 0;
         left: 50%;
         margin-left: -#{$linewidth / 2};
         z-index: 1;
      }
   }
   .text {
      margin-bottom: 2rem;
      font-style: italic;
      line-height: 1.8;
      color: $gray-600;
   }
}