// CHELSEA Application Form

table.standPricing {
   font-size: .8rem;
   td {
      cursor: default;
   }
   thead {
      td {
         font-weight: 900;
         vertical-align: middle;
         text-align: center;
      }
   }
   tbody {
      tr {
         td {
            padding: 0.6rem;
            &:first-child {
            }
            &:not(:first-child) {
               padding: 0;
               height: 1px;
               width: (80% / 5); // 5 = number of stand types
            }
         }
      }
   }
   label {
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin-bottom: 0;
      font-size: 0.9rem;
      text-align: center;
      &:not(.disabled) {
         &:hover {
            background-color: lighten($light-blue, 30%);
         }
         &.selected {
            background-color: $yellow;
         }
      }
      &.disabled {
         color: $gray-500;
      }
      &:not(.disabled),
      &:not(.disabled) input {
         cursor: pointer;
      }
      &.disabled,
      &.disabled input {
         cursor: not-allowed;
      }
   }
   input {
      position: relative;
      bottom: 1px;
      margin-right: 8px;
   }
   .radiocheckStackError {
      bottom: 13px;
   }
}

// installment pricing table in payments page
table.pricing {
   border-width: 1px;

   .pricelabel {
      td {
         font-style: italic;
         color: #6d736d;
         font-family: $font-family-serif;
      }
   }

   td {
      height: 100%;
      width: 50%;
      padding: 0;
      vertical-align: middle !important;
      text-align: center;
      line-height: 32px;

      &.price {
         font-family: $font-family-sans-serif;
         &:not(.na) {
            background-color: lighten($gray-100, 2%);
            padding: 10px;
            &:not(.checked):hover {
               background-color: $white;
            }
         }
         &.checked {
            background-color: $primary-lighter;
            cursor: default;
         }
      }
      &.price:not(.na):not(.checked):hover {
         cursor: pointer;
         label {
            cursor: pointer;
         }
      }

      &.na {
         font-weight: 800;
         font-size: 22px;
         color: #b8bbb8;
         span {
            display: inline-block;
            cursor: default;
         }
      }
   }

   .listprice, .discount, .netprice {
      font-size: 20px;
   }

   .listprice {
      text-decoration: line-through;
   }

   .discount {
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
      color: #6c826c;
      span {

         display: inline-block;
         position: relative;
         &:after {
            content: attr(data-tag);
            font-size: 14px;
            display: block;
         }
      }
   }

   .netprice {
      font-weight: 800;
      font-size: 24px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 10px;
      color: $gray-900;
   }

   label {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
   }
}