// logged in account

.accountHeader {
   position: relative;
   margin-bottom: 1rem;
   padding-right: 70px;
   * {
      font-family: $font-family-sans-serif;
   }
   h4 {
      margin-bottom: 0.5rem;
   }
   p {
      &:last-child {
         margin-bottom: 0;
      }
   }
   .btn {
      padding: .4rem .8rem;
      position: absolute;
      bottom: -0.5rem;
      right: 0;
      z-index: 1;
   }
}

.accountDetails {
   tr {
      td {
         font-size: 1.1rem;
      }
      td:first-child {
         background-color: $gray-100;
         width: 25%;
         font-weight: 700;
         font-size: 0.9rem;
         line-height: 1.6;
      }
   }
   .change {
      font-size: 0.9rem;
   }
   &.stands {
      td:first-child {
         width: 50%;
      }
   }
}

.avatarContainer {
   display: flex;
   align-items: flex-start;
   .profilePic {
      margin-right: 20px;
   }
   img {
      width: 150px;
   }
   p {
      font-family: $font-family-sans-serif;
   }
}

/*//////////////////////////////////////////
            ## HIGHLIGHTS
//////////////////////////////////////////*/
// initial start adding books message
.startBooks {
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   justify-content: center;
   align-item: center;
   text-align: center;
   border: 3px dashed $border-color;
   padding: 3.7rem 4rem 4rem;
   border-radius: 10px;
   .icon {
      position: relative;
      bottom: 1rem;
      font-size: 5rem;
      line-height: 1;
      color: $gray-400;
   }
   .message {
      align-self: center;
      max-width: 500px;
      margin-bottom: 2rem;
      font-size: 2rem;
      line-height: normal;
      color: lighten($gray-600, 10%);
      font-family: $font-family-sans-serif;
   }
   .btn {
      align-self: center;
      max-width: 300px;
   }
}

// big add a book button
.addBookBtn {
   background-color: transparent;
   position: relative;
   display: block;
   height: 0;
   padding-top: 100%;
   border: 2px dashed darken($border-color, 5%);
   border-radius: 10px;
   text-align: center;
   &:hover {
      background-color: $gray-200;
   }
   .addText {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-item: center;
      height: 100%;
      width: 100%;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      color: $gray-500;
      font-size: 1.3rem;
   }
   .icon {
      font-size: 3rem;
   }
}

// green alert that appears when you add/edit/delete a book
#successAlert {
   height: 100px;
   margin-bottom: 2rem;
   .alert {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 1.3rem;
      color: theme-color('success');
      font-weight: 700;
   }
}

// edit box icons
.edit.book .card-footer {
   display: flex;
   justify-content: space-between;
   > * {
      flex-grow: 1;
      background-color: transparent;
      border: none;
      cursor: pointer;
      margin: 0;
      padding: 5px;
      font-size: 1.3rem;
      color: $gray-800;
      &:hover {
         background-color: $gray-200;
         color: $black;
      }
      &:not(:last-child) {
         border-right: 1px solid $border-color;
      }
   }
}

// form categories
.bookCategories {
   .categoryStack {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      .custom-control {
         width: 33%;
         padding-right: 1rem;
         margin-right: 0;
      }
   }
   hr {
      border-color: $gray-300;
      width: 100%;
      margin: 0.8rem 0 1rem;
   }
   .custom-checkbox {
      font-size: 0.9rem;
   }
   .custom-control-label {
      position: relative;
      top: 1px;
   }
}

// upload book images message
.dz-message {
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   justify-content: center;
   text-align: center;
   border: 2px dashed $gray-400;
   padding: 5rem 4rem;
   border-radius: 10px;
   cursor: pointer;
   font-size: 1.3rem;
   line-height: 1.4;
   color: $gray-700;
   font-family: $font-family-sans-serif;
}

// hover background color
.dz-message:hover,
.dz-preview:hover,
.dz-preview.ui-sortable-helper {
   background-color: $wrapper-bg;
}

// dropzone upload books
.dz-preview {
   $cellpad: 20px;

   display: flex;
   background-color: $gray-200;
   align-items: center;
   padding: 10px 0;
   height: 100px;
   border-top: 1px solid $border-color;
   border-bottom: 1px solid transparent;
   border-radius: 4px;
   cursor: move;
   &:first-child {
      [data-role='dz-name'] {
         &:after {
            content: 'Album cover';
         }
      }
   }
   .dz-count {
      padding-left: 1%;
      width: 7%;
      font-family: $font-family-serif;
      font-size: 3vw;
      font-style: italic;
      font-weight: 900;
      color: $black;
   }
   .dz-image {
      margin-right: $cellpad;
      img {
         border-radius: 4px;
      }
   }
   .dz-details {
      width: 35%;
      font-size: .9rem;
   }
   .dz-progress {
      position: relative;
      width: 40%;
      padding-right: 45px;
   }
   .dz-controls {
      display: flex;
      margin-left: auto;
      text-align: right;
      font-size: 1.7rem;
   }
   .dz-success-mark,
   .dz-error-mark {
      cursor: default;
   }
   .dz-success-mark {
      color: lighten(theme-color('primary'), 7%);
   }
   .dz-error-mark {
      color: theme-color('warning');
   }
   .dz-remove {
      margin-left: 10px;
      color: theme-color('danger');
      &:hover {
         color: #d32b28;
      }
   }
   &.ui-sortable-helper {
      border-bottom: 1px solid $border-color;
   }
}



/*//////////////////////////////////////////
            ## FORM WIZARD
//////////////////////////////////////////*/
.form-wizard {
   padding-top: 0;
   .title {
      background-color: $gray-300;
      position: relative;
      bottom: 30px;
      margin: 0 -30px;
      padding: 20px 30px;
   }
}

.nav-wizard {
   flex-direction: column;
   font-size: .8rem !important;
   color: $gray-800;
   font-weight: 700;
   counter-reset: section;
   a {
      display: block;
      position: relative;
      padding: 15px 5px 15px map-get($spacers, 5);
      border: none;
      border-top: 1px solid lighten($gray-400, 6%);
      text-transform: uppercase;
      color: inherit;
      font-weight: inherit;
      font-size: inherit;
      &:before {
         counter-increment: section;
         content: counter(section) '.';
         position: relative;
         right: 10px;
      }
      &:not(.disabled, .active):hover {
         background-color: $gray-300;
      }
      &.active {
         cursor: default;
      }
      &.disabled {
         cursor: default;
         background-color: darken($gray-200, 2%);
         color: $gray-500;
      }
      &.error, &.completed {
         &:after {
            position: absolute;
            right: 10px;
            top: 50%;
            z-index: 1;
            margin-top: -10px;
            font-family: 'Fontello';
            font-size: 1.2rem;
            line-height: normal;
         }
      }
      &.completed {
         color: theme-color('primary');
         &:after {
            content: '\e811';
            color: inherit;
         }
      }
      &.error {
         color: $danger !important;
         &:after {
            content: '\e825';
            color: inherit;
         }
      }
   }
}

.wizard-buttons {
   display: flex;
   justify-content: space-between;
   flex-direction: row-reverse;
   margin-top: 2rem;
   padding-top: 1.7rem;
   border-top: 2px solid $gray-300;
}