// scroll to top button

#scrollup {
   display: none;
   justify-content: center;
   align-items: center;
   background-color: $gray-600;
   width: 40px;
   height: 40px;
   border: none;
   border-radius: 3px;
   opacity: .5;
   position: fixed;
   bottom: 10px;
   right: 10px;
   z-index: $zindex-fixed;
   cursor: pointer;
   font-size: 1.1rem;
   color: $white;
   &:hover {
      opacity: 1;
      color: $primary-light;
   }
   .icon {
      position: relative;
      bottom: 2px;
   }
}

.modal-open #scrollup {
   transition: none;
   opacity: 0;
}