// Highlights section

/*//////////////////////////////////
         ## LISTING
//////////////////////////////////*/
#bookFilters {
   h3 {
      font-size: 1.2rem;
   }
   input,
   select,
   .input-group-addon {
      padding: 0.5rem;
   }
   select {
      height: 50px;
   }
}

#priceRange {
   display: flex;
   .input-group {
      width: 50%;
      &.min {
         padding-right: 12px;
         position: relative;
         &:after {
            content: '—';
            position: absolute;
            top: 9px;
            right: -7px;
            z-index: 1;
            font-weight: 900;
            font-size: 0.9rem;
         }
      }
      &.max {
         padding-left: 12px;
      }
   }

}

// single book card item
.book {
   text-align: center;
   color: $body-color;
   .card-title {
      color: $gray-900 !important;
   }
   .card-text {
      font-size: 0.9rem;
   }
   .card-body {
      display: flex;
      flex-direction: column;
   }
   .card-footer {
      font-weight: 800;
   }
   &.edit {
      box-shadow: 0 0 0 0 transparent;
      transition: box-shadow 3s ease-in-out;
      .card-footer {
         padding: 0;
      }
      &.success {
         box-shadow: 0 0 12px 1px #6db55e;
         transition: box-shadow 0s ease-in-out;
      }
   }
   &.reorder {
      .bookImg, .card-body, .card-footer {
         cursor: move;
      }
   }

   // exhibitor in list item
   .relatedExhibitor {
      display: block;
      border-top: 1px solid $border-color;
      margin-top: 10px;
      padding-top: 10px;
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 1.4;
   }
}

// top images in a book card
.bookImg {
   position: relative;

   .card-img-overlay {
      display: none;
      &.viewBook {
         cursor: pointer;
      }
      &.reorderBook {
         cursor: move;
      }
   }
   &:hover .card-img-overlay,
   .card-img-overlay.loading,
   .card-img-overlay.hover {
      background-color: rgba(21, 23, 21, .5);
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: $card-inner-border-radius;
      border-top-right-radius: $card-inner-border-radius;
      z-index: 1;
      width: 100%;
      height: 100%;
      &:after {
         font-family: 'Fontello';
         font-size: 1.8rem;
         color: $white;
      }
   }

   &:hover {
      .card-img-overlay {
         &.viewBook {
            &:after {
               content: '\e828';
            }
         }
         &.reorderBook {
            &:after {
               content: '\f047';
            }
         }
      }
   }

   .pending {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      padding: 2px;
      background-color: rgba(239, 133, 3, 0.9);
      cursor: default;
      color: $white;
      text-align: center;
      text-transform: uppercase;
      font-size: .7rem;
      font-weight: 700;
      font-family: $font-family-sans-serif;
   }
}

/*//////////////////////////////////
         ## DETAILS
//////////////////////////////////*/
$hideTabsBreakpoint: 'lg';

.highlightSubtitle {
   position: relative;
   bottom: 10px;
   font-family: $font-family-sans-serif;
   .btn {
      margin-top: 10px;
   }
}

.highlight {
   hr {
      margin: map-get($spacers, 7) 0;
   }

   @include media-breakpoint-up($hideTabsBreakpoint) {
      &.tab-content > .tab-pane {
         display: block;
      }
   }
}

.details {
   list-style: none;
   padding: 0;
   margin: 0;
   font-size: 1rem;
   font-family: $font-family-sans-serif;
   * {
      font-family: inherit;
   }
   &.xl {
      font-size: 1.2rem;
   }
   &.lg {
      font-size: 1.05rem;
   }
   &.md {
      font-size: 1rem;
   }
   &.sm {
      font-size: 0.9rem;
   }
   &.xs {
      font-size: 0.7rem;
   }
   li, .item {
      display: flex;
      flex-wrap: wrap;
   }
   strong {
      margin-right: 7px;
   }
}

.pendingAlert {
   background-color: rgba(239, 133, 3, 0.9);
}

.keywordsList {
   > * {
      margin: 0 6px 6px 0;
   }
}

// image slider for viewing a book
#bookShowcase {
   .carousel-inner {
      margin-bottom: 1rem;
   }

   .carousel-control-next,
   .carousel-control-prev {
      background-color: rgba(39, 39, 39, 0.7);
      border-radius: $border-radius;
      width: 32px;
      height: 32px;
      top: 50%;
      margin-top: (-32px / 2);
      &:focus {
         color: $carousel-control-color;
         text-decoration: none;
         outline: 0;
         opacity: $carousel-control-opacity;
      }
   }
   .carousel-control-next {
      right: 5px;
   }
   .carousel-control-prev {
      left: 5px;
   }

   .carousel-lightbox {
      display: none;
   }

   @include media-breakpoint-up($hideTabsBreakpoint) {
      .carousel-lightbox {
         $lbSize: 50px;
         cursor: pointer;
         align-items: center;
         justify-content: center;
         background-color: rgba(39, 39, 39, 0.7);
         position: absolute;
         right: 0;
         top: 0;
         z-index: 1;
         width: $lbSize;
         height: $lbSize;
         border: none;
         border-radius: 0 0 0 3px;
         color: $white;
         font-size: 1.5rem;
      }
      .carousel-inner:hover .carousel-lightbox {
         display: flex;
      }
   }

   .carousel-control-next-icon,
   .carousel-control-prev-icon {
      width: 16px;
      height: 16px;
   }
   .carousel-control-next-icon {
      left: 3px;
   }
   .carousel-control-prev-icon {
      left: 2px;
   }

   // thumbnails
   .carousel-indicators {
      display: flex;
      justify-content: flex-start;
      position: static;
      left: initial;
      width: initial;
      margin: 0 -5px;
      white-space: nowrap;
      > li {
         display: inline-block;
         width: 20%;
         height: initial;
         text-indent: initial;
         margin: 0;
         padding: 0 5px;
         img {
            border: 1px solid transparent;
            border-radius: 3px;
         }
         &.active {
            img {
               border-color: $primary-light;
            }
         }
      }
   }
}

#modal-lightbox {
   .modal-body {
      max-height: calc(100vh);
      overflow-y: auto;
      background-color: $black;
   }
   .carousel-inner {
      text-align: center;
      img {
         max-width: 100vw;
         height: auto;
         width: auto;
         max-height: calc(100vh);
      }
   }
}