// Exhibitors

/*//////////////////////////////////
        EXHIBITOR LISTING
//////////////////////////////////*/

.userSearch {
   form {
      margin-bottom: 30px;
      input[type="search"] {
         margin-bottom: 20px;
         flex-grow: 5;
      }
      .btn {
         flex-grow: 1;
      }
   }
   @include media-breakpoint-up(sm) {
      form {
         input[type="search"] {
            margin-bottom: 0;
            margin-right: 20px;
         }
      }
   }
   @include media-breakpoint-up(lg) {
      form {
         margin-bottom: 0;
      }
   }
}

.sorting {
   display: flex;
   align-items: center;
   height: 54px;
   .sortLabel {
      margin-right: 20px;
      font-weight: bold;
      text-transform: uppercase;
   }
   label {
      margin-bottom: 0;
      top: 3px;
      cursor: pointer;
   }
   @include media-breakpoint-down(md) {
      height: auto;
      flex-wrap: wrap;
      align-items: flex-start;
      .sortLabel {
         width: 100%;
      }
      label {
      }
   }
}

.alpha {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   &:after {
      content: '';
      flex: auto;
   }
   .letter {
      background-color: transparent;
      display: inline-block;
      border-radius: 2px;
      padding-left: 10px;
      padding-right: 10px;
      height: 40px;
      line-height: 40px;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
   }
   .anchor {
      color: theme-color('primary');
      &:hover {
         background-color: theme-color('primary');
         color: $white;
      }
   }
   .empty {
      color: $gray-500;
      cursor: default;
   }
   @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
      &:after {
         flex: none;
      }
   }
}

.chapter {
   font-family: $font-family-serif;
   color: theme-color('primary');
   cursor: default;
   margin-bottom: 0;
}

.exhibitorsList {
   list-style: none;
   margin: 0;
   padding: 0;

   li {
      border-bottom: 1px solid $border-color;
      font-family: $font-family-sans-serif;
      > a {
         margin: 0 -10px;
         padding: 1rem 10px;
         display: flex;
         &:hover {
            background-color: $gray-200;
         }
      }
      a > * {
         display: inline-block;
         line-height: 1.2rem;
      }
   }
   .code {
      font-weight: bold;
      margin-right: 10px;
      width: 50px;
      color: $gray-800;
   }
   .name {
      color: $gray-600;
   }
   .catalogue {
      margin-left: 10px;
      color: $gray-600;
   }
   .more {
      margin-left: auto;
      padding-left: 10px;
      > span {
         display: none;
      }
   }
}

/*//////////////////////////////////
        EXHIBITOR DETAILS
//////////////////////////////////*/

.exhibitorStand {
   position: relative;
   bottom: 15px;
   margin: 0;
   font-family: $font-family-sans-serif;
   font-weight: 700;
   font-size: 1.4rem;
}

.exhibitorInfo {
   font-size: 0.8rem;
   line-height: 1.5rem;
   address {
      line-height: 1.3rem;
   }
}

// profile pic sitewide
.profilePic {
   background-color: $white;
   padding: 4px;
   border-radius: 3px;
}

.defaultProfile {
   background-color: $gray-400;
   opacity: 0.7;
}

.exhibitorInfo, .exhibitorsList {
   .picContainer {
      width: 160px;
      margin-right: 40px;
   }
   img {
      width: 100%;
   }
   @include media-breakpoint-down(md) {
      .media {
         display: block;
      }
   }
}