// modals

.close {
   cursor: pointer;
   outline: none;
   &:active, &:focus {
      outline: none;
   }
}

.modal-full {
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   width: 100%;
   max-width: 100%;
   margin: 0;
   .modal-content {
      border: none;
      border-radius: 0;
      box-shadow: none;
      height: 100%;
   }
}

.modal-content {
   border: none;
}

.modal {
   .container-fluid, .container {
      padding-top: $modal-inner-padding;
      padding-bottom: $modal-inner-padding;
      @include clearfix;
   }
}

.modal-header, .modal-footer {
   padding-top: 10px;
   padding-bottom: 10px;
}

.modal-body {
   padding: 0;
}

#modal-error {
   #errorMessage {
      font-family: $font-family-sans-serif;
      * {
         font-family: inherit;
      }
      h1, h2 {
         @extend h3;
      }
      pre {
         background-color: $gray-200;
         padding: 5px 7px;
         font-size: 0.8rem;
      }
   }
}