// Sidebar Styling

.sidebar {
   font-size: .9rem;
   font-family: $font-family-sans-serif;
   line-height: 1.5;
   p, ul, ol {
      font-family: inherit;
      font-size: inherit;
   }
   .btn, p {
      &:last-child {
         margin-bottom: 0;
      }
   }
}

.sidenav {
   .inner {
      padding-bottom: 10px !important;
   }
   .rootTitle {
      a:not(:hover) {
         color: inherit;
      }
   }
   ul, ol {
      padding: 0;
      margin: 0;
   }
   ul {
      list-style: none;
   }
   li {
      a {
         display: flex;
         flex-wrap: wrap;
         position: relative;
         padding: 15px 5px 15px map-get($spacers, 6);
         background-color: darken($gray-200, 2%);
         border-top: 1px solid $gray-400;
         color: $gray-800;
         font-weight: 700;
         text-transform: uppercase;
         font-size: .8rem;
         &:hover {
            background-color: $gray-300;
            color: $link-color;
         }
         &:before {
            content: '';
            position: absolute;
            top: 16px;
            left: 14px;
            z-index: 1;
         }
      }

      &.active {
         a {
            background-color: $gray-700;
            color: $gray-100;
            &:before {
               content: '\e802';
            }
         }
      }
   }

   .badge {
      padding: 0;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      font-size: .7rem;
      line-height: 1.1rem;
      text-align: center;
   }
}