// typography

// scaling text
@include media-breakpoint-up(xs) {
   html {
      font-size: 19px;
   }
}

@include media-breakpoint-up(lg) {
   html {
      font-size: 18px;
   }
}

.abac {
   @include clearfix;
}

.font-family-serif {
   font-family: $font-family-serif;
}

.font-family-sans-serif {
   font-family: $font-family-sans-serif;
}

// paragraphs
p {
   font-family: $font-family-serif;
}

// highlighting
::selection {
   background: $mark-bg; /* WebKit/Blink Browsers */
}

::-moz-selection {
   background: $mark-bg; /* Gecko Browsers */
}

mark, .mark {
   color: $body-color;
   display: inline-block;
}

// links
a, .link {
   color: $link-color;
   transition: all 0.2s ease-out;
   cursor: pointer;
   -webkit-transform: translateZ(0);
   -webkit-backface-visibility: hidden;
   &:hover {
      color: $link-hover-color;
      text-decoration: none;
      .logo {
         opacity: .6;
      }
   }
   &:focus {
      color: $link-hover-color;
      text-decoration: none;
   }
}

a, .link, button, .btn {
   &:active, &:focus {
      outline: none;
   }
}

p a, .link {
   font-weight: 700;
   border-bottom: 5px solid transparent;
   &:hover {
      border-color: $gray-300;
   }
}

strong, b, .font-weight-bold {
   color: $gray-800;
}

// headers
@for $i from 1 through 6 {
   h#{$i}, .h#{$i} {
      color: $gray-900;

      @include media-breakpoint-down(sm) {
         word-break: break-word;
      }
   }
   p, figure, iframe, img, ul, ol, table, section, .blockquote, .btn, .btn-link {
      & + h#{$i} {
         margin-top: map-get($spacers, 7);
      }
   }
}

h3, h4, .h3, .h4 {
   margin-bottom: map-get($spacers, 2);
}

h5, h6, .h5, .h6 {
   margin-bottom: map-get($spacers, 1);
}

@include media-breakpoint-down(sm) {
   h1, .h1 {
      font-size: $h1-font-size - 0.3;
   }
   h2, .h2 {
      font-size: $h2-font-size - 0.3;
   }
   h3, .h3 {
      font-size: $h3-font-size - 0.2;
   }
   h4, .h4 {
      font-size: $h4-font-size - 0.1;
   }
}

// blockquote
.blockquote {
   background-color: $gray-200;
   padding: 30px map-get($spacers, 10);
   border-radius: $border-radius;
   border-bottom: $border-edge;
   p {
      font-family: $font-family-sans-serif;
      line-height: 1.2;
      font-weight: 700;
      position: relative;
      &:before, &:after {
         font-family: $font-family-serif;
         color: $gray-500;
      }
      &:before {
         content: '\201C';
         position: absolute;
         left: -33px;
         top: -6px;
         z-index: 1;
         font-size: 2.8rem;
      }
      &:after {
         content: '\201D';
      }
   }
}

.blockquote-footer {
   font-family: $font-family-serif;
   font-style: italic;
}

// lists
ul, ol {
   font-family: $font-family-serif;
}

ul {
   &.arrowed, &.circled, &.contacts {
      list-style: none;
      margin-bottom: 0;
      li {
         margin-bottom: 5px;
         position: relative;
         right: 8px;
      }
   }
   &.arrowed, &.circled {
      li:before {
         font-family: 'fontello';
         color: $link-hover-color;
         font-weight: normal;
         left: -20px;
         top: 5px;
         position: absolute;
         font-size: 12px;
      }
   }
   &.arrowed {
      li:before {
         content: '\e805';
      }
   }
   &.circled {
      li:before {
         content: '\f10c';
      }
   }
}