// site header

.nav-item {
   span {
      border-bottom: 2px solid transparent;
      padding: 3px 0;
   }
   &.active {
      span {
         border-color: $white;
      }
   }
}

.navbar {
   @include media-breakpoint-between(xs, lg) {
      .container {
         width: 100%;
         max-width: none;
      }
   }
}

.navbar-nav {
   * {
      font-family: $font-family-sans-serif;
   }
   @include media-breakpoint-down(sm) {
      padding: 1rem 0;
   }
   @include media-breakpoint-only(xl) {
      margin-left: -1rem;
   }
}

.navbar .navbar-nav .nav-link {

   text-transform: uppercase;

   // mobile
   @include media-breakpoint-between(xs, sm) {
      display: inline-block;
      font-size: 0.9rem;
   }

   // tablet
   @include media-breakpoint-between(md, lg) {
      padding-top: 1rem;
      padding-bottom: 1rem;
   }
   @include media-breakpoint-only(md) {
      font-size: 0.7rem;
   }
   @include media-breakpoint-only(lg) {
      padding-right: 1rem;
      padding-left: 1rem;
      font-size: .9rem;
   }
   @include media-breakpoint-up(xl) {
      font-size: .9rem;
      padding: 1rem 1.5rem;
   }
}

.navbar .navbar-nav {
   @include media-breakpoint-only(md) {
      &.items6 {
         .nav-link {
            font-size: .7rem;
         }
      }
      &.items7 {
         .nav-link {
            font-size: .55rem;
         }
      }
      &.items8 {
         .nav-link {
            font-size: .5rem;
         }
      }
   }
   @include media-breakpoint-only(lg) {
      &.items6 {
         .nav-link {
            font-size: .9rem;
         }
      }
      &.items7 {
         .nav-link {
            font-size: .75rem;
         }
      }
      &.items8 {
         .nav-link {
            font-size: .6rem;
         }
      }
   }
   @include media-breakpoint-only(xl) {
      &.items6 {
         .nav-link {
            font-size: .9rem;
         }
      }
      &.items7 {
         .nav-link {
            font-size: .75rem;
         }
      }
      &.items8 {
         .nav-link {
            font-size: .6rem;
         }
      }
   }
}

/*//////////////////////////
      Header (Top Part)
//////////////////////////*/
.top {
   display: flex;
   width: 100%;
   background-color: $white;
   padding: 10px 0;
   color: $black;
   @include media-breakpoint-up(lg) {
      border-top: 3px solid $gray-400;
   }
}

// BATTERSEA'S header
.batterseaHeader {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;

   .abaLogos {
      display: none;
   }
   @include media-breakpoint-up(lg) {
      flex-wrap: no-wrap;
      align-items: center;
      .abaLogos {
         display: flex;
         align-items: center;
         a {
            display: block;
            padding: 5px;
            &:hover {
               opacity: 0.7;
            }
         }
      }
   }
}

// CHELSEA'S header
.chelseaHeader {
   display: flex;
}

//@include media-breakpoint-up(xl) {
//	.navbar-brand {
//		margin-left: -30px;
//	}
//}
.navbar-brand {
   &:hover {
      opacity: 0.7;
   }
}

.logo-text {
   padding-left: 10px;
   font-weight: 700;
   font-size: 1.4rem;
   line-height: 1.2;
   cursor: default;
   span {
      display: block;
      &.first {

      }
      &.second {
         font-weight: 900;
      }
      &.third {

      }
   }
}

.nav-search {
   @include media-breakpoint-down(sm) {
      display: none;
   }
   .fair-info {
      margin-bottom: 8px;
      font-size: .9rem;
   }
   .form-control {
      padding-top: .4rem;
      padding-bottom: .4rem;
   }
   .search-btn {
      @extend .form-control;
      cursor: pointer;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
      padding-left: 10px;
      padding-right: 10px;
      &:hover {
         color: lighten(theme-color('primary'), 10%);
      }
   }
   .search-field {
      padding-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
      &:focus {
         border-color: $input-border-color;
      }
   }
}

/*//////////////////////////
      Main Navigation
//////////////////////////*/
.nav {
   width: 100%;
   background-color: $primary-dark;
}

// social icons
.navbar .social {
   list-style: none;
   display: flex;
   margin: 0;
   padding: 0;
   li {
      &.fb a:hover {
         background-color: $facebook-color;
      }
      &.tw a:hover {
         background-color: $twitter-color;
      }
      &.ig a:hover {
         background-color: $instagram-color;
      }
   }
   a {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border-radius: 4px;
      color: $navbar-dark-color;
      &:hover {
         color: $navbar-dark-hover-color;
      }
   }
   span {
      display: none;
   }

   // mobile
   @include media-breakpoint-down(sm) {
      padding-bottom: 1.5rem;

      li {
         margin-right: 15px;
         &.fb a {
            background-color: $facebook-color;
         }
         &.tw a {
            background-color: $twitter-color;
         }
         &.ig a {
            background-color: $instagram-color;
         }
      }
   }
}