// everything too small to have its own file

// Style Guide
.toc {
   padding: 15px;
   background-color: $gray-200;
   font-size: 0.8rem;
   border-radius: $border-radius;
   border-bottom: $border-edge;
   * {
      font-family: $font-family-sans-serif;
   }
   ul {
      > li {
         font-weight: 700;
      }
      ul {
         padding-left: 8px;
         list-style: none;
         li {
            font-size: 0.7rem;
            font-weight: 400;
         }
      }
   }
}

// Homepage
.hpMap {
   img {
      border: 7px solid $gray-400;
   }
   .figure, img {
      margin-bottom: 0;
   }
}

.hpWhen {
   border-bottom: 1px solid $table-border-color;
   font-family: $font-family-serif;
   td {
      padding-top: 19px;
      padding-bottom: 19px;
   }
}

.hpWhere {
   position: relative;
   bottom: 12px;
}

.hpExImage {
   $hpImgColor: theme-color();
   border: 7px solid lighten($hpImgColor, 5%);
   img {
      margin-bottom: 0;
   }
}

// Partners
.partnerLogo {
   padding: 20px;
   a img {
      &:hover {
         opacity: 0.7;
      }
   }
}