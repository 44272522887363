// buttons

.btn {
   font-size: map-get($spacers, 2);
   letter-spacing: 0.02em;
   text-shadow: none;
   -webkit-font-smoothing: antialiased;
   text-transform: uppercase;
   border-radius: 3px;
   cursor: pointer;
   border: none;

   &.disabled, &[disabled] {
      background-color: $btn-link-disabled-color;
      border-color: $btn-link-disabled-color;
      &:hover, &:active, &:focus {
         background-color: $btn-link-disabled-color;
         border-color: $btn-link-disabled-color;
      }
   }
}

.btn, .btn:hover, .btn:focus, .btn:active, .btn[disabled] {
   box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
   //	transition: all 200ms ease-out;
}

// maintain buttons' text color on :focus
.btn-primary,
.btn-success,
.btn-danger,
.btn-dark {
   &:focus {
      color: $white;
   }
}

.btn-secondary,
.btn-info,
.btn-warning,
.btn-light {
   &:focus {
      color: $gray-900;
   }
}

.btn-sm {
   font-size: map-get($spacers, 1);
}

.btn-lg {
   font-size: 0.9rem;
}

.box,
.module,
.endcta,
.form {
   .btn {
      white-space: normal;
   }
}

.btn-link {
   background: none;
   font-size: $font-size-base;
   font-family: $font-family-serif;
   font-style: italic;
   font-weight: 700;
   color: $link-color;
   border: none;
   padding-left: 0;
   padding-right: 0;
   transition: all 0.2s ease-out;
   cursor: pointer;
   transform: translateZ(0);
   -webkit-backface-visibility: hidden;
   &:hover {
      color: $link-hover-color;
      text-decoration: none;
   }
   &:focus {
      color: $link-hover-color;
      text-decoration: none;
   }
   &:after {
      font-family: 'fontello';
      font-size: 75%;
      content: '\e805';
      position: relative;
      left: 4px;
      bottom: 2px;
      font-style: normal;
      transition: all 200ms ease-out;
   }
   &:hover {
      &:after {
         left: 8px;
      }
   }
}