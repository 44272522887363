// Alerts

.alert {
   font-family: $font-family-sans-serif;
   padding: 10px 15px;
   border-radius: 3px;
   cursor: default;
   * {
      font-family: $font-family-sans-serif;
   }
   .header {
      font-weight: $headings-font-weight;
      font-size: 20px;
      line-height: $headings-line-height;
      margin-bottom: 12px;
   }
   p:last-child {
      margin-bottom: 0;
   }
}

// fix yellow alert background coloring
.alert-info {
   background-color: #ebe5d2;
   border-color: #ebdbaa;
}

#cookies {
   padding: 30px 0;
   background-color: $gray-900;
   color: $gray-100;
   font-weight: bold;
   font-size: 16px;
   * {
      font-family: $font-family-sans-serif;
   }
   p {
      margin-bottom: 10px;
   }
   a {
      color: $link-color;
      border-color: $link-color;
      &:hover {
         color: $link-hover-color;
         border-color: $link-hover-color;
      }
   }

   .btn {
      margin: 0;
   }
}

.alert {
   .close {
      line-height: 28px;
   }
}