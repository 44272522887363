// payments

.stripeField {
   cursor: text;
}

.standItem {
   p {
      margin-bottom: 0;
      color: $body-color;
      font-family: $font-family-sans-serif;
      font-size: 1.2rem;
   }
}

table.invoice {
   tr td, tr th {
      &:last-child, &.money {
         text-align: right;
      }
   }
   tr {
      td:last-child, td.money {
         width: 20%;
         letter-spacing: 1.2px;
      }
   }
   tr.addup td:first-child {
      text-align: right;
      font-weight: bold;
   }
   td.small {
      width: 8%;
   }

   td.br {
      background: $table-border-color;
      padding: 1px;
   }

   tr.total {
      td {
         border-top: 2px solid $primary-light;
         background-color: $primary-lighter;
         font-weight: bold;
         font-size: 1.1rem;
         color: $primary-dark;
      }
   }
}

.purchaseControls {
   display: flex;
   align-items: center;
   background-color: lighten($gray-200, 2%);
   padding: map-get($spacers, 2);
   border: 2px solid $gray-300;
   border-radius: $border-radius;

   &.selected {
      background-color: $primary-lighter;
      border-color: $primary-light;
   }

   * {
      font-family: $font-family-sans-serif;
   }
   .btn {
      margin-left: 10px;
   }
   .quantity {
      margin-right: 20px;
      font-weight: bold;
   }
   .price {
      margin-right: auto;
      font-weight: bold;
   }
}
