// Tabs

.nav-tabs {
   font-family: $font-family-sans-serif;
   font-weight: 700;
   text-transform: uppercase;
   font-size: .8rem;
   .nav-link {
      color: $gray-700;
      &:not(.active):hover {
         background-color: $gray-200;
         color: $link-hover-color;
      }
      &.active {
         cursor: default;
         border-bottom: 1px solid $wrapper-bg;
      }
   }
   &.nav {
      background-color: transparent;
   }
}

.tab-content {
   padding-top: map-get($spacers, 5);
}