// for testing different use case scenarios

.testing {
   tr {
      &.true {
         td {
            background-color: #e5f2e7; // light green
            color: $primary;
         }
      }
      &.false {
         td {
            background-color: #f4e3e3; // pink
            color: $danger;
         }
      }
   }
}